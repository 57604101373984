.top-bg {
  background: #6f5835;
}

.carousel-img {
  height: 60vh;
  object-fit: cover;
}
.full-img {
  width: 100%;
}

.tarjeta {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  transition: 0.5s;
}

.tarjeta:hover {
  box-shadow: 2px 2px 18px rgba(0, 0, 0, 1);
}

.bg-light2 {
  background-color: #e6e6e6;
}

.nav-item {
  font-family: 'Barlow Condensed';
  font-weight: 600;
}

.reserva {
  transition: 0.2s;
}

.reserva:hover {
  transform: scale(0.98);
}

div.wysiwyg ul li {
  margin-left: 5%;
  list-style: disc;
}

.nav-logo {
  width: 100%;
}

@media only screen and (max-width: 760px) {
  .nav-logo {
    width: 50%;
    margin-left: 25%;
  }
}
